
.Media {
  background: black;
  margin: 5px 0 auto;
  width: 100%;
  position: relative;
}
.Media:after {
  content: '';
  display: block;
  width: 100%;
  height: 0;
  padding-top: 56.25%;
}
.Media__content {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}
.Media__content video,
.Media__content iframe {
  width: 100%;
  height: 100%;
}

.Image {
  margin: 5px 0 auto;
  width: 100%;
  position: relative;
}
.Image__content {
  display: block;
  width: 100%;
  height: auto;
}
