.Gifts {
}
.Bubbles {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  margin-top: 80px;
}
.Bubble {
  position: relative;
  z-index: 150;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background: rgba(44, 174, 10, 0.2);
  margin: 0 15px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
}

.Bubble[data-has-fill] {
  background: none;
}
.Bubble[data-has-fill]:before {
  content: '';
  position: absolute;
  top: -11px;
  left: -11px;
  border: 11px solid rgba(44, 174, 10, 0.2);
  width: 142px;
  height: 142px;
  border-radius: 50%;
}

.Bubble span {
  font-size: 84px;
  font-family: house-script, sans-serif;
  font-weight: 400;
  color: #2cae0a;
  opacity: 0.5;
  margin-top: 10px;
  margin-left: -5px;
}

.Bubble img {
  width: 150%;
  height: 150%;
  margin: -43px -30px 4px;
  position: relative;
}
