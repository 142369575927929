
.Button,
.PrimaryButton,
.SecondaryButton {
  display: block;
  border-radius: 24px;
  appearance: none;
  border: none;
  color: white;
  text-align: center;
  padding: 14px 24px 10px;
  width: 100%;
  max-width: 240px;
  margin: 0 auto 15px;
  font-family: house-script, sans-serif;
  font-weight: 400;
  line-height: 24px;
  font-size: 28px;
  outline: none;
}
.Button:last-child,
.PrimaryButton:last-child,
.SecondaryButton:last-child {
  margin-bottom: 0;
}

.PrimaryButton {
  position: relative;
  color: white;
  background: #d40f3d;
  box-shadow: 0 3px 0 #a80c32;
}
.PrimaryButton:before,
.PrimaryButton:after {
  content: '';
  position: absolute;
  background-image: url(../../assets/images/holly2.svg);
  background-size: 100% auto;
  background-position: top left;
  background-repeat: no-repeat;
}
.PrimaryButton:before {
  width: 32px;
  height: 28px;
  transform: rotate(-60deg);
  top: -8px;
  left: -16px;
}
.PrimaryButton:after {
  width: 56px;
  height: 48px;
  transform: rotate(-240deg);
  top: 25px;
  right: -34px;
}

.SecondaryButton {
  background: #2cae0a;
  box-shadow: 0 3px 0 #237f05;
}
