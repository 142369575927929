
.TextBubble {
  width: 88%;
  margin: 25px auto auto;
  border-radius: 14px;
  background-color: rgba(35, 127, 5, 0.2);
  padding: 24px 20px;
  font-size: 20px;
  letter-spacing: 0.91px;
  text-align: center;
  font-family: canada-type-gibson, sans-serif;
  font-weight: 300;
  line-height: 1.2;
  color: #f4f4f4;
}

.TextBubble h1 {
  font-family: house-script, sans-serif;
  font-weight: 400;
  line-height: 1em;
  font-size: 36px;
  letter-spacing: 1.64px;
  margin: 0;
}

.TextBubble p {
  margin: 0;
}
.TextBubble p + p {
  margin-top: 1em;
}
.TextBubble h1 + p {
  margin-top: 6px;
}
