.Title {
  display: block;
  max-width: 90%;
  margin: 50px auto 100px;
}

.Title[data-size="large"] {
  max-width: 75%;
  margin: 50px auto 100px;
}
.Title[data-size="medium"] {
  max-width: 66%;
  margin: 0 auto -40px;
  position: relative;
}


.privacyLink {
  font-size: 14px;
  opacity: 0.7;
  margin: 10px auto -10px;
  text-align: center;
  color: white;
};
