
.Page {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  background: gold;
  font-family: canada-type-gibson, sans-serif;
  color: white;
  background: radial-gradient(#D2E764, #56A33B);
}

.Frame {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.Frame:before {
  content: '';
  position: absolute;
  pointer-events: none;
  z-index: 200;
  width: 100%;
  height: 100%;
  background-image: url('../../assets/images/overlay_tl.svg'), url('../../assets/images/overlay_tr.svg'), url('../../assets/images/overlay_bl.svg'), url('../../assets/images/overlay_br.svg');
  background-position: top left, top right, bottom left, bottom right;
  background-repeat: no-repeat;
  background-size: 50% auto, 50% auto, 50% auto, 50% auto;
}

.Center {
  margin: auto;
}

.Content {
  flex: 1;
  padding-top: 50px;
}
.RibbonTitle + .Content {
  padding-top: 70px;
}
.Actions {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 50px;
}
@media (min-height: 677px) {
  .Actions[data-size="tall"] {
    min-height: 160px;
  }
}

.RibbonTitle {
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  height: 120px;
  background-image: url(../../assets/images/ribbon.png);
  background-size: 96% auto;
  background-position: center center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}
.RibbonTitle:before,
.RibbonTitle:after {
  content: '';
  position: absolute;
  background-image: url(../../assets/images/holly2.svg);
  background-size: 100% auto;
  background-position: top left;
  background-repeat: no-repeat;
}
.RibbonTitle:before {
  width: 60px;
  height: 55px;
  transform: rotate(-225deg);
  top: -13px;
  left: -13px;
}
.RibbonTitle:after {
  width: 85px;
  height: 76px;
  transform: rotate(-120deg);
  top: -15px;
  right: -22px;
}
.RibbonTitle__inner {
  font-family: house-script, sans-serif;
  font-weight: 400;
  line-height: 24px;
  font-size: 45px;
  margin-top: 4px;
}
